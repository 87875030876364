@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Light.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-LightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: url("./Poppins-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}